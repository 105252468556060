<template>
  <div class="world dealer-page">
    <div class="wrapper-title">
      <div class="container">
        <router-link :to="{name: 'ww.dashboard'}" class="text-wilmsLightBlue font-100 block">&larr; {{ $t('Back to overview') }}</router-link>
        <div class="flex">
          <h1>{{ $t('Dealerinformatie') }}</h1>
        </div>
      </div>
    </div>

    <div class="container">
      <tabs :options="{ useUrlFragment: false }">
        <tab :name="$t('Dealerinformatie')">
          <formulate-form v-if="dealer && dealerData" ref="dealerForm" v-model="dealerData" class="dealer-form" name="dealerForm">
            <div>
              <h2>{{ $t('Uploads') }}</h2>
              <formulate-input v-if="!images[dealerData.logo_file_id]" :help="$t('Upload hier je logo door te slepen of te bladeren')" :label="$t('Logo')" :upload-url="uploadUrl" :uploader="uploadLogo" class="logo-upload" error-behavior="submit" name="logo_file" type="file" upload-behavior="live" :validation-messages="{ mime: $t('Logo moet van dit type zijn: jpeg, png of gif.')}" validation="mime:image/jpeg,image/png,image/gif"></formulate-input>
              <div v-else class="image-placeholder">
                <img :src="images[dealerData.logo_file_id].url" alt="Alt img" width="50"/>
                <button class="btn btn-remove-file" type="button" @click="deleteLogo">{{ $t('Logo verwijderen') }}</button>
              </div>

              <formulate-input v-if="!images[dealerData.terms_conditions_invoices_file_id]" :help="$t('Upload hier de algemene voorwaarden door te slepen of te bladeren')" :label="$t('Algemene voorwaarden')" :upload-url="uploadUrl" :uploader="uploadTerms" class="terms-upload" error-behavior="submit" name="term_file" type="file" upload-behavior="live" :validation-messages="{ mime: $t('Algemene voorwaarden moet van dit type zijn: pdf.')}" validation="mime:application/pdf"></formulate-input>
              <div v-else class="image-placeholder">
                <Icon color="#0f1d61" icon="bxs:file-pdf" width="50"/>
                <button class="btn btn-remove-file" type="button" @click="deleteTerms">{{ $t('Algemene voorwaarden verwijderen') }}</button>
              </div>
            </div>

            <div>
              <h2>{{ $t('Algemene informatie') }}</h2>
              <formulate-input :label="$t('Naam')" disabled name="name" type="text" validation="required"></formulate-input>

              <formulate-input :wrapper-class="['address']" name="address" type="group">
                <formulate-input :disabled="dealer.address.address1" :label="$t('Straat en nummer')" name="address1" type="text" validation="required"></formulate-input>
                <formulate-input :disabled="dealer.address.locality" :label="$t('Gemeente')" name="locality" type="text" validation="required"></formulate-input>
                <formulate-input :disabled="dealer.address.postal_code" :label="$t('Postcode')" name="postal_code" type="text" validation="required"></formulate-input>
              </formulate-input>

              <formulate-input :disabled="dealer.vat_number" :label="$t('BTW-nummer')" name="vat_number" type="text"></formulate-input>
            </div>

            <div>
              <h2>{{ $t('Contactgegevens') }}</h2>
              <formulate-input :disabled="dealer.phone" :label="$t('Telefoonnummer')" name="phone" type="text" validation="required"></formulate-input>
              <formulate-input :disabled="dealer.email" :label="$t('E-mail')" name="email" type="text" validation="required"></formulate-input>
            </div>

            <div class="col-2 vacation-periods">
              <h2 v-html="getTranslation(globals.dealer_vacation_title).value"></h2>
              <div class="description" v-html="getTranslation(globals.dealer_vacation_body).value"></div>
              <formulate-input v-model="dealerVacations" :add-label="$t('+ Periode toevoegen')" :remove-label="$t('Periode verwijderen')" :repeatable="true" class="vacation-periods-group" name="vacation_periods" type="group">
                <template #default="{ index }">
                  <div>
                    <formulate-input name="id" type="hidden"></formulate-input>
                    <formulate-input :label="$t('Van')" name="start_date" type="date" validation="required"></formulate-input>
                  </div>
                  <formulate-input :label="$t('Tot ')" :min="setMinDate(index)" name="end_date" type="date" validation="required"></formulate-input>
                </template>
                <template v-slot:remove="props">
                  <div class="remove" @click="props.removeItem">
                    <img alt="" src="@/assets/img/close.svg">
                  </div>
                </template>
                <template v-slot:addmore="props">
                  <button class="btn btn-inverse" type="button" @click="props.addMore">{{ props.addLabel }}</button>
                </template>
              </formulate-input>
            </div>
          </formulate-form>

          <div class="buttons">
            <button class="btn" @click="saveDealer()">{{ $t('Dealerinformatie opslaan') }}</button>
          </div>
        </tab>

        <tab v-if="checkPermissions(['quote.create'])" :name="$t('Calculator configuratie')">
          <div v-if="dealer" class="configuration">
            <div class="dealer-form">
              <div class="col-2 extra-options-wrapper">
                <formulate-form @submit="saveExtraOptions" v-if="extraOptionsData.current && extraOptionsData.current.length > 0" ref="extraOptionsForm" v-model="extraOptionsData" :debounce="100" class="extra-options" name="extraOptionsForm">
                  <formulate-input #default="{ index }" groupRepeatable-class="extra-option-row translations-group" name="current" type="group">
                    <div v-if="extraOptionsData.current[index] && extraOptionsData.current[index].id" class="row">
                      <div class="data">
                        <formulate-input :label="$t('Titel extra optie')" name="title" type="text" validation="required"></formulate-input>
                        <formulate-input :label="$t('Prijs')" name="price" type="number" validation="required"></formulate-input>
                        <formulate-input :label="$t('Omschrijving')" class="description" name="description" type="text" validation="required"></formulate-input>
                        <formulate-input :label="$t('Prijs tonen')" class="show-price" name="show_price" type="checkbox"></formulate-input>
                      </div>
                      <button type="button" class="btn-remove" @click="deleteExtraOption(extraOptionsData.current[index].id, index)">
                        <Icon color="#cf5353" icon="bi:x" width="20"/>
                      </button>
                    </div>
                  </formulate-input>

                  <button class="btn" type="submit" @click="saveExtraOptions">{{ $t('Configuratie opslaan') }}</button>
                </formulate-form>
              </div>

              <div>
                <formulate-form ref="extraOptionForm" v-model="extraOptionData" class="extra-options" name="extraOptionForm">
                  <div class="extra-option-row new">
                    <formulate-input :label="$t('Titel extra optie')" name="title" type="text" validation="required"></formulate-input>
                    <formulate-input :label="$t('Omschrijving')" name="description" type="text" validation="required"></formulate-input>
                    <formulate-input :label="$t('Prijs')" name="price" type="number" validation="required"></formulate-input>
                    <formulate-input :label="$t('Prijs tonen')" class="show-price" name="show_price" type="checkbox"></formulate-input>
                  </div>
                  <button class="btn" @click="createExtraOptions">{{ $t('Extra optie toevoegen') }}</button>
                </formulate-form>
              </div>
            </div>
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import { Tabs, Tab } from 'vue-tabs-component'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    Tabs, Tab, Icon
  },
  data () {
    return {
      isLoading: false,
      uploader: ApiService.getAxiosInstance(),
      uploadUrl: '/files',
      logoFileData: null,
      termsFileData: null,
      dealerData: null,
      dealerVacations: [],
      globals: {
        dealer_vacation_title: null,
        dealer_vacation_body: null
      },
      extraOptionsData: {
        current: []
      },
      extraOptionData: null,
      defaultOptions: ['Plaatsing (hele dag)', 'Plaatsing (halve dag)', 'Lift/kraan (halve dag)', 'Puincontainer (hele dag)', 'Dossierkost']
    }
  },
  async mounted () {
    if (!(this.checkPermissions(['dealer.editOwn']) || this.checkPermissions(['dealer.edit']))) {
      await this.$router.push({ name: 'ww.dashboard' })
    }

    await this.setDealer()
    this.dealerData = JSON.parse(JSON.stringify(this.dealer))
    await this.setDealerData()

    this.globals.dealer_vacation_title = (await ApiService.fetchGlobal('loyalty', 'dealer.vacation.title')).data
    this.globals.dealer_vacation_body = (await ApiService.fetchGlobal('loyalty', 'dealer.vacation.body')).data

    if (this.checkPermissions(['quote.create'])) {
      await this.fetchExtraOptions()

      if (this.extraOptionsData.current.length === 0) {
        for (const defaultOptionTitle of this.defaultOptions) {
          const defaultOption = {
            title: defaultOptionTitle,
            description: '',
            price: 1
          }

          await ApiService.createQuoteDealerExtra(this.dealer.id, defaultOption).then(res => {
            this.extraOptionsData.current.push(res.data)
          })
        }
      }
    }
  },
  methods: {
    setMinDate (index) {
      if (index !== undefined && this.dealerVacations[index].start_date) {
        return this.dealerVacations[index].start_date
      }

      return ''
    },
    async fetchExtraOptions () {
      await ApiService.fetchDealerQuoteExtras(this.dealer.id).then(res => {
        this.extraOptionsData.current = res.data
      })
    },
    async createExtraOptions () {
      this.$formulate.submit('extraOptionForm')
      if (await this.$refs.extraOptionForm.hasValidationErrors()) {
        return
      }
      this.$formulate.resetValidation('extraOptionForm')

      await ApiService.createQuoteDealerExtra(this.dealer.id, this.extraOptionData).then(res => {
        this.extraOptionsData.current.push(res.data)
        this.$formulate.reset('extraOptionForm')
      })
    },
    async saveExtraOptions () {
      this.$formulate.submit('extraOptionsForm')
      if (await this.$refs.extraOptionsForm.hasValidationErrors()) {
        return
      }
      this.$formulate.resetValidation('extraOptionsForm')

      for (const option of this.extraOptionsData.current) {
        await ApiService.patchQuoteDealerExtra(this.dealer.id, option)
      }

      notification(this.$t('Extra opties succesvol opgeslagen!'))
    },
    async deleteExtraOption (id, index) {
      this.extraOptionsData.current.splice(index, 1)
      await ApiService.deleteQuoteDealerExtra(this.dealer.id, id)
    },
    async setDealerData () {
      if (this.dealerData) {
        this.dealerData.address = [this.dealerData.address]
        const fileIds = []

        if (this.dealerData.logo_file_id) {
          fileIds.push({ image_file_id: this.dealerData.logo_file_id })
        }

        if (this.dealerData.terms_conditions_invoices_file_id) {
          fileIds.push({ image_file_id: this.dealerData.terms_conditions_invoices_file_id })
        }

        await this.$store.dispatch('fetchImages', { objects: fileIds })

        // this.logoFileData = this.dealerData.logo_file_id ? (await ApiService.getFile(this.dealerData.logo_file_id)).data : null
        // this.termsFileData = this.dealerData.terms_conditions_invoices_file_id ? (await ApiService.getFiles([this.dealerData.terms_conditions_invoices_file_id)]).data : null
        this.dealerVacations = this.dealerData.vacations
      }
    },
    async saveDealer () {
      this.$formulate.submit('dealerForm')
      if (await this.$refs.dealerForm.hasValidationErrors()) {
        return
      }
      this.$formulate.resetValidation('dealerForm')

      this.isLoading = true

      const dealer = JSON.parse(JSON.stringify(this.dealerData))
      dealer.address = dealer.address[0]
      dealer.users = dealer.users.map(user => user.id)

      const newDealerVacations = this.dealerVacations.filter(dealerVacation => dealerVacation.id === undefined && !(dealerVacation.start_date === undefined || dealerVacation.start_date === null) && !(dealerVacation.end_date === undefined || dealerVacation.end_date === null))
      const existingDealerVacations = this.dealerVacations.filter(dealerVacation => dealerVacation.id !== undefined)
      const removedDealerVacations = this.dealer.vacations.filter(vacation => !this.dealerVacations.find(vac => vac.id === vacation.id))

      if (newDealerVacations.length > 0) {
        await ApiService.createDealerVacations(dealer.id, { periods: newDealerVacations })
      }

      for (const dealerVacation of existingDealerVacations) {
        await ApiService.saveDealerVacation(dealer.id, dealerVacation.id, dealerVacation)
      }

      for (const dealerVacation of removedDealerVacations) {
        await ApiService.deleteDealerVacation(dealer.id, dealerVacation.id)
      }

      await ApiService.saveDealerInformation(dealer.id, dealer).then(async res => {
        if (res.status === 200) {
          notification(this.$t('Dealer informatie succesvol opgeslagen!'))

          const dealerId = localStorage.getItem('chosenDealerId')
          await ApiService.fetchDealer(dealerId).then(async (res) => {
            this.dealer = res.data
            this.dealerVacations = this.dealer.vacations
            const index = this.currentUserDealers.findIndex(dealer => dealer.id === this.dealer.id)
            this.$store.state.currentUserDealers[index] = this.dealer
          })
        }
      })

      this.isLoading = false
    },
    async uploadLogo (file, progress, error, option) {
      this.isUploading = true

      const formData = new FormData()
      formData.append('files', file)
      const res = await ApiService.uploadFiles(formData)

      if (res.status === 200) {
        this.isUploading = false
        // this.logoFileData = (await ApiService.getFile(fileId)).data
        this.dealerData.logo_file_id = res.data[0]
        await this.$store.dispatch('fetchImages', { value: 'logo_file_id', objects: [this.dealerData] })

        // this.dealerData.files = null
      }
    },
    async uploadTerms (file, progress, error, option) {
      this.isUploading = true

      const formData = new FormData()
      formData.append('files', file)
      const res = await ApiService.uploadFiles(formData)

      if (res.status === 200) {
        this.isUploading = false
        // const fileId = res.data[0]
        // this.termsFileData = (await ApiService.getFile(fileId)).data
        this.dealerData.terms_conditions_invoices_file_id = res.data[0]
        await this.$store.dispatch('fetchImages', {
          value: 'terms_conditions_invoices_file_id',
          objects: [this.dealerData]
        })

        // this.dealerData.files = null
      }
    },
    async deleteLogo () {
      await ApiService.deleteFile(this.dealerData.logo_file_id)
      this.dealerData.logo_file_id = null
      this.logoFileData = null
    },
    async deleteTerms () {
      await ApiService.deleteFile(this.dealerData.terms_conditions_invoices_file_id)
      this.dealerData.terms_conditions_invoices_file_id = null
      this.termsFileData = null
    },
    addVacationRow () {
      this.dealerVacations.push({
        start_date: '',
        end_date: ''
      })
    },
    removeVacationRow (props) {
      props.removeItem()

      // if (this.dealerVacations.length === 0) {
      //   this.addVacationRow()
      // }
    }
  },
  computed: {
    images () {
      return this.$store.getters.getImages
    }
  }
}
</script>
